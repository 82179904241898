import React from "react"
import PropTypes from "prop-types"
import useSiteDetailsFromBuild from "@modules/site/shared/hooks/useSiteDetailsFromBuild"
import BuildDetailsInner from "./DetailsInner"

function LogsPage({ buildId, location }) {
  const { siteDetails, orgDetails, loading } = useSiteDetailsFromBuild(buildId)

  return siteDetails ? (
    <BuildDetailsInner
      buildId={buildId}
      organization={orgDetails}
      site={siteDetails}
      loading={loading}
      location={location}
    />
  ) : null
}

LogsPage.propTypes = {
  buildId: PropTypes.string,
}

export default LogsPage
