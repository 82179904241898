import { useQuery } from "react-apollo"
import { SITE_DETAILS_BY_BUILD_QUERY } from "../queries"

export default function useSiteDetailsDataFromBuild(buildId) {
  const { loading, data, error } = useQuery(SITE_DETAILS_BY_BUILD_QUERY, {
    variables: { buildId: buildId },
    fetchPolicy: `cache-and-network`,
  })

  return {
    loading:
      loading &&
      !(data && data.siteDetailsByBuildId && data.siteDetailsByBuildId.id),
    siteDetails: data && data.siteDetailsByBuildId,
    orgDetails:
      data &&
      data.siteDetailsByBuildId &&
      data.siteDetailsByBuildId.organization,
    error,
  }
}
