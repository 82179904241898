import gql from "graphql-tag"
import { SiteDetailsFieldsFragmentDoc } from "@modules/site/shared/fragments.generated"

export const SITE_DETAILS_BY_BUILD_QUERY = gql`
  query siteDetailsByBuildIdQuery($buildId: UUID!) {
    siteDetailsByBuildId(buildId: $buildId) {
      ...siteDetailsFields
    }
  }
  ${SiteDetailsFieldsFragmentDoc}
`
